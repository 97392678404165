import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {}

const stories = [
  {
    title: "Uygulamaların Kırılganlığı",
    postId: "ca3f849a6939",
  },
  {
    title: "LearnReactUI.dev Free Ebooks For Developers",
    postId: "1f50330930f",
  },
  {
    title: "Frontend ve Yazılım Mimarisi Çalışma Notlarım",
    postId: "5634ce97997f",
  },
  {
    title: "Çalışma Notlarında Güncellemeler (Nisan 2023)",
    postId: "9e4cd0ec77cc",
  },
  {
    title: "Gezi Notlarım",
    postId: "da36f65d310f",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Not Güncellemeleri",
  path: "note-updates",
}

const NoteUpdates = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default NoteUpdates
